html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}

body {
	line-height: 1;
}

ol, ul {
	list-style: none;
}

blockquote, q {
	quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}

table {/* tables still need 'cellspacing="0"' in the markup */
	border-collapse: collapse;
	border-spacing: 0;
}

/* Mixin Variables
-------------------------------------------------------------- */
/* This is the default html and body font-size for the base em value. */

/* Since the typical default browser font-size is 16px... */
$em-base:16px;
/* Working in ems is annoying. Think in pixels by using this handy function, emCalc(#px) */
@function emCalc($pxWidth) {
  @return $pxWidth / $em-base * 1em;
}


/* ClearFix
-------------------------------------------------------------- */

.clearfix:before, .clearfix:after { content: ""; display: table; }
.clearfix:after { clear: both; }
/* .clearfix { zoom: 1; } for IE only */

/* @font-face Fonts
-------------------------------------------------------------- */

@font-face {font-family: 'FONTREF';
	src: url('fonts/FONTNAME-webfont.eot?') format('eot'), 
		 url('fonts/FONTNAME-webfont.woff') format('woff'), 
		 url('fonts/FONTNAME-webfont.ttf') format('truetype'),
		 url('fonts/FONTNAME-webfont.svg#svgFontName') format('svg');}
		 
/* Layout
-------------------------------------------------------------- */
$green:#7bbd53;
$dark-green:#649d41;
$blue:#28acd2;
$orange:#e9741e;
$pink:#e43688;
		 
html, body {font-size:100%;}

body {
	font-family:Arial, 'Helvetica Neue', Helvetica, sans-serif;
	font-size:1em;
	color:#666;
	background:#f4f4f4 url(f/bg-texture.png) repeat top left;
	font-smooth:auto;
	-webkit-font-smoothing:antialiased;
}

a {text-decoration:none; outline:0; color:$green;}
a:hover {text-decoration:underline;}
a:hover, a:active {outline:0;}
a.phone-number, a.phone-number:hover {cursor:text; text-decoration:none;}

/* Main Body Styling
-------------------------------------------------------------- */
.container {
	width:940px;
	padding:0 20px;
	margin:0 auto;
	position:relative;
}

.green-stripe {
	display:block;
	background:$green;
	width:100%;
	height:8px;
	border-bottom:1px solid #fff;
}

.global-header {
	border-bottom:1px solid #e1e1e1;
	-webkit-box-shadow:0px 1px 0px 0px #fff;
    box-shadow:0px 1px 0px 0px #fff;
    margin-bottom:20px;
	
	.container {
		height:215px;
	}

}

.quick-contact {
	font-size:emCalc(14px);
	background:$green;
	display:inline-block;
	padding:10px;
	color:#fff;
	position:absolute;
	top:-1px;
	right:20px;
	border-bottom-left-radius:4px;
	border-bottom-right-radius:4px;
	border-bottom:1px solid #fff;
}

.logo {
	background:transparent url(f/groundworks-logo.png) no-repeat center center;
	text-indent:-9999em;
	overflow:hidden;
	position:absolute;
	bottom:20px;
	left:20px;
	a {
		display:block;
		width:266px;
		height:124px;
	}
}

.tag-line {
	width:625px;
	height:23px;
	background:transparent url(f/tag-line.png) no-repeat center center;
	text-indent:-9999em;
	overflow:hidden;
	position:absolute;
	right:20px;
	top:88px;
	opacity:0.6;
}

.global-nav {

	ul {
		position:absolute;
		bottom:16px;
		right:-10px;
		cursor:default;
		font-size:18px;
	}

	li {
		display:inline;
		text-transform:lowercase;
		margin-right:35px;

		a {
			color:$dark-green;
			text-decoration:none;
			border-bottom:4px solid transparent;
			padding-bottom:3px;

			&:hover {
				border-color:#999;
				-webkit-transition: border-color .3s linear;
		  		-moz-transition: border-color .3s linear;
			}

			&.stick {
				color:#666;
				border-color:$green;
				cursor:default;
			}

		}

	}

}

.bttn {
	display:inline-block;
	font-size:emCals(22px);
	font-weight:bold;
	text-align:center;
	background:$dark-green;
	color:#fff;
	height:40px;
	line-height:40px;
	padding:0 30px;
	border-radius:3px;
	text-decoration:none !important;
	-webkit-box-shadow: inset 0px 1px 0px 0px rgba(255, 255, 255, 0.3), 0px 5px 5px 0px rgba(0, 0, 0, .25);
    box-shadow: inset 0px 1px 0px 0px rgba(255, 255, 255, 0.3), 0px 5px 5px 0px rgba(0, 0, 0, .25);

	&:hover {
		background:$green;
		-webkit-box-shadow:0px 3px 3px 0px rgba(0, 0, 0, .25);
    	box-shadow:0px 3px 3px 0px rgba(0, 0, 0, .25);
	}
}

.main {
	min-height:380px;
}

.watermark-illustration {
		position:absolute;
		z-index:5;
}

.typical-challenges, .people {

	.watermark-illustration {
		width:397px;
		height:454px;
		background:transparent url(f/bg-full-stalk.jpg) no-repeat center center;
		bottom:-55px;
		right:0;
	}

}

.philosophy {

	.watermark-illustration {
		width:350px;
		height:166px;
		background:transparent url(f/bg-top-fluff.jpg) no-repeat center center;
		bottom:-55px;
		right:0;
	}

}

.projects {

	.watermark-illustration {
		width:653px;
		height:270px;
		background:transparent url(f/bg-floating-seeds.jpg) no-repeat center center;
		top:-20px;
		right:0;
	}

}


.copy {
	position:relative;
	z-index:10;
	width:700px;

	h1 {
		font-size:emCalc(30px);
		font-weight:bold;
		margin-bottom:25px;
	}

	h2, h3 {
		font-size:emCalc(24px);
		font-weight:bold;
		margin-bottom:-10px;

		&.company {
			color:$blue;
		}

		&.teams {
			color:$orange;
		}

		&.individuals {
			color:$pink;
		}

	}

	p {
		margin:20px 0;
		line-height:1.5em;
	}

	ul {
		list-style:disc;
		margin:20px 0 20px 40px;
		color:$green;

		&.company {
			color:$blue;
		}

		&.teams {
			color:$orange;
		}

		&.individuals {
			color:$pink;
		}

	}

	li {
		line-height:1.5em;
		margin:10px 0;

		span {
			color:#666;
		}
	}

	strong {
		font-weight:bold;
	}

	img.portrait {
		float:left;
		margin:0 20px 16px 0;
	}

	.hr {
		display:block;
		width:100%;
		height:1px;
		background:#e1e1e1;
		border-bottom:1px solid #fff;
		clear:both;
	}

	.big {
		font-size:emCalc(24px);
	}

	.highlight {
		color:$green;
	}

	.cta {
		text-align:right;
	}

}

.projects .copy {
	width:100%;
	padding-bottom:5px;
}

.project {
	position:relative;
	height:auto;
	clear:both;
	margin-bottom:62px;

	p, li {
		line-height:1.3em;
	}

	p {
		margin:15px 0;
	}

	.box {
		position:absolute;
		top:0;
		left:0;
		display:block;
		width:160px;
		min-height:160px;
		height:100%;
		background:orange;
		font-size:emCalc(24px);
		font-weight:bold;
		color:#fff;

		.label {
			padding:10px 0 0 10px;
		}
	}

	&.company {

		.box {
			background:$blue;
		}

		ul, h2 {
			color:$blue;
		}

	}

	&.teams {

		.box {
			background:$orange;
		}

		ul, h2 {
			color:$orange;
		}

	}

	&.individuals {

		.box {
			background:$pink;
		}

		ul, h2 {
			color:$pink;
		}

	}

	.project-info {
		float:right;
		width:700px;
		margin-right:60px;
	}

	.project-more {
		display:none;
	}

	.toggler {
		position:absolute;
		bottom:-40px;
		left:0;
		width:100%;
		height:25px;
		line-height:20px;
		text-align:right;
		font-size:emCalc(14px);

		a {
			padding-right:20px;
			text-decoration:none;

			&.open {
				color:$dark-green;

				&:hover {
					color:$green;
				}

				svg {
					transform: rotate(180deg);
				}

			}

			&:hover {
				color:$dark-green;
			}
		}

		svg {
			position:absolute;
			top:3px;
			right:0;
			width:16px;
			height:16px;

			path {
				fill: $dark-green;
			}
		}

	}

	.hr {
		position:absolute;
			bottom:-45px;
			left:0;
	}

	h2, h3 {
		font-size:emCalc(18px);

		span {
			font-size:emCalc(12px);
			color:#999;
		}

	}

}

.global-footer {
	margin-top:55px;
	background:#fff;
	border:1px solid #dedede;
	border-left:0;
	border-right:0;
	height:130px;
	position:relative;

	.contact-details {
		position:absolute;
		right:60px;
		top:55px;
		text-transform:lowercase;
		font-size:emCalc(15px);

		li {
			display:inline;
			margin-left:20px;
		}
	}

	.watermark {
		background:transparent url(f/footer-watermark.png) no-repeat center left;
		position:relative;
		z-index:5;
		display:block;
		position:absolute;
		top:-85px;
		left:-200px;
		height:313px;
		width:321px; 
	}

}

.credits {
	text-align:right;
	font-size:emCalc(10px);
	text-transform:uppercase;
	margin-top:5px;
	color:#999;

	a {
		color:#666;
	}
}

/* UNLESS YOU KNOW WHAT YOU'RE DOING, DO NOT CHANGE THE FOLLOWING RULES */
.easy-accordion{display:block;position:relative;overflow:hidden;padding:0;margin:0}
.easy-accordion dt,.easy-accordion dd{margin:0;padding:0}
.easy-accordion dt,.easy-accordion dd{position:absolute}
.easy-accordion dt{margin-bottom:0;margin-left:0;z-index:5;cursor:pointer;
/* fix chrome bug -webkit-backface-visibility: hidden;*/
/* CSS3 */ transform: rotate(-90deg); 
/* Opera */ -o-transform: rotate(-90deg); 
/* Webkit */ -webkit-transform: rotate(-90deg); 
/* Firefox */ -moz-transform: rotate(-90deg); 
/* Internet Explorer 9 */ -ms-transform: rotate(-90deg);
}
.lteIE8 .easy-accordion dt {/* Internet Explorer <9 */ filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);cursor:pointer; }

.easy-accordion dd{z-index:1;opacity:0;overflow:hidden}
.easy-accordion dd.active{opacity:1;}
.easy-accordion dd.no-more-active{z-index:2;opacity:1}
.easy-accordion dd.active{z-index:3}
.easy-accordion dd.plus{z-index:4}
.easy-accordion .slide-number{position:absolute;bottom:0;font-weight:normal;font-size:1.1em;
/* fix chrome bug -webkit-backface-visibility: hidden;*/
/* CSS3 */ transform: rotate(90deg);
/* Opera */ -o-transform: rotate(90deg);
/* Webkit */ -webkit-transform: rotate(90deg); 
/* Firefox */ -moz-transform: rotate(90deg);  
/* Internet Explorer 9 */ -ms-transform: rotate(90deg);
}
.lteIE8 .easy-accordion .slide-number{ /* Internet Explorer <9*/ filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1); }

/* Homepage Promo - IE Fixes */
.oldie .easy-accordion{display:block;position:relative;overflow:hidden;padding:0;margin:0;}
.oldie .easy-accordion dt,.oldie .easy-accordion dd{margin:0;padding:0;}
.oldie .easy-accordion dt,.oldie .easy-accordion dd{position:absolute;}
.oldie .easy-accordion dt{
	margin-bottom:0;
	margin-left:0;
	z-index:5;
	-webkit-transform: rotate(-90deg);
	-moz-transform: rotate(-90deg);
	-moz-transform-origin: 20px 0px;
	filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
	cursor:pointer;}
.oldie .easy-accordion dd{z-index:1;opacity:0;overflow:hidden;}
.oldie .easy-accordion dd.active{opacity:1;}
.oldie .easy-accordion dd.no-more-active{z-index:2;opacity:1;}
.oldie .easy-accordion dd.active{z-index:3;}
.oldie .easy-accordion dd.plus{z-index:4;}
.oldie .easy-accordion .slide-number{
	position:absolute;
	bottom:0;
	left:10px;
	font-weight:normal;
	font-size:1.1em;
	-webkit-transform:rotate(90deg);
	-moz-transform:rotate(90deg);
	filter:progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
}
/* // Homepage Promo - IE Fixes */

#home-slides {
			width:920px;
			height:360px;
			padding:10px 5px 10px 10px;
			background:white url(f/bg-slides.jpg) no-repeat center center;

			dl {
				width:920px;
				height:360px;
			}

			dt {
				height:75px;
				line-height:70px;
				text-align:left;
				font-size:emCalc(30px);
				text-transform:lowercase;
				font-weight:bold;
				color:#fff;

				.background {
					padding-left:15px;
					height:70px;
					position:relative;

					.pointer {
						position:absolute;
						bottom:-39px;
						right:80px;
						border:20px outset transparent;
					    border-top:red 20px solid;
					    display:block;
					    height:0;
					    width:0;
					    display:none;
					}

				}

				&.companies {

					.background {
						background:$blue;
					}

					.pointer {
						border-top-color:$blue;
					}

				}

				&.teams {

					.background {
						background:$orange;
					}

					.pointer {
						border-top-color:$orange;
					}

				}

				&.individuals {

					.background {
						background:$pink;
					}

					.pointer {
						border-top-color:$pink;
					}

				}

				&.active {
					cursor:default;
					color:#fff;

					&.hover {
						color:#fff;

						&.companies {

							.background {
								background:$blue;
							}

						}

						&.teams {

							.background {
								background:$orange;
							}

						}

						&.individuals {

							.background {
								background:$pink;
							}

						}

					}

					.pointer {
						display:block;
					}

					+ dd {
						opacity:1;
					}

				}

				&.hover{

					&.companies {

						.background {
							background:lighten($blue,4%);
						}

					}

					&.teams {

						.background {
							background:lighten($orange,4%);
						}

					}

					&.individuals {

						.background {
							background:lighten($pink,4%);
						}

					}

				}

				+ dd {
					opacity:0;
					-webkit-transition: opacity .3s linear;
		  			-moz-transition: opacity .3s linear;
				}

			}

			dd {
				margin-left:20px;
				margin-right:10px;
				padding:85px 25px 25px 25px;
				background:#333;
				background:rgba(0,0,0,0.5);
				color:#fff;

				.title {
					display:block;
					font-size:emCalc(30px);
					font-weight:bold;
					margin-bottom:15px;
					text-transform:lowercase;
				}

				p {
					font-size:emCalc(18px);
					line-height:1.5em;
				}

				strong {
					font-weight:bold;
				}

				.companies strong {
					color:$blue;
				}

				.teams strong {
					color:$orange;
				}

				.individuals strong {
					color:$pink;
				}

				.bttn {
					position:absolute;
					bottom:88px;
					right:40px;
				}

			}

		}

.oldie #home-slides .pointer {
	display:none !important;
}